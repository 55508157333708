import server from "@/utils/request";


// 发请求的操作封装成函数  mock

// 首页
export function getIndex(data){
    return server({
        method:"POST",
        url:"/api/index/index",
        data
    })
}
// 提交咨询
export function consultSub(data){
    return server({
        method:"POST",
        url:"/api/index/message",
        data
    })
}