<template>
  <div id="app">
    <router-link to="/leavewords"></router-link>
    <router-view />
  </div>
</template>

<script>
  import Wow  from 'wow.js'
  import { routes } from "@/router";
  export default {
    data(){
      return {
        
      }
    },
    routes() {
      return routes[0].children;
    },
    created() {
      // this.fetchSeoList()
    },
    mounted(){
      // console.log(routes);
      const wow = new Wow({
        boxClass: 'wow',
        animateClass: 'animate__animated',
        // 这个对应的是你的动画的前缀类名
        mobile: true,
        live: true
      })
      wow.init()
    },
    methods:{
      
    }
  }
</script>

<style lang="scss">
  
</style>
