<template>
  <div class="main primary_color">

    <!-- banner -->
    <div class="w-per100">
      <img class="full-width" :src="bg" alt="">
      <!-- <div class="ft48 bolder mt20">{{ title }}</div> -->
    </div>

    <!-- 留言 -->
    <div id="contactUs" class="consultBox column a_center w-per100 minh696">
      <div class="ft32 mt100">
        {{ title }}
      </div>
      <div class="w-per50 column a_center">
        <div class="w-per100 mt100 mb80">
          <el-form :inline="false" label-width="80px" label-position="left" :model="ruleForm">
              <el-form-item label="姓名" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="phone">
                <el-input v-model="ruleForm.phone" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="留言" prop="text">
                <el-input v-model="ruleForm.text" type="textarea" :autosize="{ minRows: 5, maxRows: 10}" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="" prop="checked">
                <el-checkbox v-model="ruleForm.checked">我已知晓并接受</el-checkbox><el-button type="text" @click="open">《个人信息收集使用规则》</el-button>
              </el-form-item>
            </el-form>
            <div class="w-per50 mxauto py20 bg_4a467e-333333 mt100 column flex_center pointer" @click="to_sub">
              <div class="white_color ft28">
                {{ sub_text }}
              </div>
            </div>
        </div>

      </div>
    </div>

    <!-- 弹窗 -->
    <el-dialog :show-close="true" :visible.sync="pop" width="60%" center>
      <div class="w-per100">
        <div class="text_center py20">个人信息收集使用规则</div>
        <div class="w-per100 mt20 maxvh50 o-y-h">
          <div class="contentBox"></div>
        </div>
        <div class="w-per30 mxauto mt40 py10 bg_4a467e-333333 column flex_center pointer" @click="pop=false">
          <div class="white_color ft24">
            关 闭
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getIndex,consultSub } from "@/api/app"

export default {
  // metaInfo() {
  //   return {
  //     title: '车联展-留言',
  //     meta: [
  //       {
  //         name: "keywords",
  //         content: this.PagekeyWords,
  //       },
  //       {
  //         name: "description",
  //         content: this.PageDescription,
  //       },
  //       {
  //         property: "og:type",
  //         content: 'website',
  //       },
  //       {
  //         property: "og:title",
  //         content: this.PageTitle,
  //       },
  //       {
  //         property: "og:description",
  //         content: this.PageDescription,
  //       },
  //       {
  //         property: "og:site_name",
  //         content: this.PageTitle,
  //       },
  //       {
  //         property: "og:url",
  //         content: 'https://message.daiweicd.com/#/',
  //       },
  //       {
  //         property: "og:image",
  //         content: 'https://message.daiweicd.com/favicon.ico',
  //       },
  //     ],
  //   };
  // },
  data() {
    return {
      // PageTitle: "",
      // PagekeyWords: "",
      // PageDescription: "",
      pop:false,
      ruleForm: {
        name: "",//姓名
        phone: "",//联系方式
        text: "",
        checked:false,
      },
      sub_text:'',
      title:'',
      bg:'',
      content:'',
      current: 0,
    }
  },
  mounted() {
    this.current = 0
    this.fetchIndex();
  },
  methods: {
    open(){
      this.pop = true;

      setTimeout(()=>{
        let rich = document.querySelector('.contentBox');
        console.log(rich);
        rich.innerHTML = this.content;
      },10)
    },
    // 提交
    async to_sub() {
      // console.log("表单", this.ruleForm);
      if(!this.checkForm()){
        return ;
      }else{
        let {data} = await consultSub({
          name: this.ruleForm.name,
          phone: this.ruleForm.phone,
          text: this.ruleForm.text,
        })
        if (data.code == 200) {
          this.$message({
            message: "提交成功",
            type: "success",
          })
          this.ruleForm.name = '';
          this.ruleForm.phone = '';
          this.ruleForm.text = '';
        } else {
          this.$message.error(data.msg)
        }
      }
    },
    checkForm(){
      if(this.ruleForm.name == ''){
        this.$message.error('请输入您的姓名');
        return false;
      }else if(this.ruleForm.phone == ''){
        this.$message.error('请输入联系方式');
        return false;
      }else if(this.ruleForm.checked == false){
        this.$message.error('请阅读《个人信息收集使用规则》');
        return false;
      }else{
        return true;
      }
    },
    async fetchIndex() {
      let {data} = await getIndex();
      if (data.code == 200) {
        console.log(data.data);
        this.title = data.data.title;
        this.content = data.data.content;
        this.bg = data.data.image;
        this.sub_text = data.data.sub_name;
      } else {
        this.$message.error(data.data.msg)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: 100%;
  margin: 0px;
  background-color: #fff;
  overflow-x: hidden;

  .full-width {
    width: 100%; /* 宽度自适应容器 */
    height: auto; /* 高度保持比例 */
    display: block; /* 避免图片下方出现空白 */
  }

  // 勾选
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label{
    color:#606266 !important;
  }
  ::v-deep .el-checkbox__inner{
    border: 1px solid #DCDFE6 !important;
  }
  // 按钮
  ::v-deep .el-button--text{
    color: #3d8af5 !important;
  }
  .w-per50{
    width: 50%;
  }
  @media screen and (max-width: 650px) {
    .w-per50{
      width: 90%;
    }
  }
}

</style>
